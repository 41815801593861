@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
body {
    font-family: 'Roboto',sans-serif;
    margin: 20px;
  }
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    padding-left: 3rem;
    width: 800px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    font-size: 26px;
    outline-color: #07215a;
  }
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    font-size: 26px;
    /* max-height: 143px; */
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0.5rem;
    width: 854px; 
  }

  .suggestions::-webkit-scrollbar {
    display: none;
  }
  .suggestions li {
    padding: 0.5rem;
    
  }
  .suggestion-active,
  .suggestions li:hover {
    background-color: #1157ee;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
  }
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

  .map-container {
    height: 85vh;
    overflow: hidden;
    overscroll-behavior: none;
    }

    ::-webkit-scrollbar {
      display: none;
  }